import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { gsap } from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import { window, document } from "browser-monads"

const FadeLink = ({ children, to, ...props }) => {
  return (
    <TransitionLink
      exit={{
        length: 0.5,
      }}
      entry={{
        length: 0.5,
      }}
      trigger={async pages => {
        gsap.registerPlugin(ScrollToPlugin)
        // wait until we have access to both pages
        const exit = await pages.exit
        const entry = await pages.entry

        const hash = to.includes("#") ? to.substring(1) : null
        // You could measure the entry element here
        // start exit animation based on measurements if you want
        // wait for the entering page to become visible
        entry.node.style.opacity = 0

        // the entering page is visible here.
        // if you want you can animate it now!
        await entry.visible
        gsap.to(exit.node, 0.35, {
          opacity: 0,
          onStart: () => {
            document.body.classList.add("no-scroll")
          },
          onComplete: () => {
            if (hash) {
              gsap.to(window, { duration: 0, scrollTo: hash })
            } else {
              window.scroll(0, 0)
            }
            gsap.to(entry.node, 0.35, {
              opacity: 1,
              onComplete: () => {
                document.body.classList.remove("no-scroll")
              },
            })
          },
        })
      }}
      to={to}
      preventScrollJump={true}
      {...props}
    >
      {children}
    </TransitionLink>
  )
}

export default FadeLink
